<template>
    <figure
        :class="[
            'bg-gray-150 relative flex items-center justify-center overflow-hidden',
            !src && aspectRatioClass,
        ]"
    >
        <img
            v-if="src"
            :src="src"
            :alt="alt"
            :class="['size-full object-cover', aspectRatioClass]"
        >
        <AspectIcon
            v-else
            class="size-1/2 max-w-64 text-gray-400"
            :name="fallbackIconName"
        />
    </figure>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = withDefaults(defineProps<{
        subject?: 'generic' | 'logo' | 'person' | 'product';
        src?: string | null;
        alt?: string;
        aspectRatio?: 'full' | 'wide' | 'ultrawide' | 'picture' | 'portrait' | 'square' | 'unset';
    }>(), {
        subject: 'generic',
        aspectRatio: 'square',
    });

    const fallbackIconName = computed(() => {
        return {
            generic: 'landscape-2',
            logo: 'lens-shutter',
            person: 'user-single-neutral',
            product: 'shipping-box-2',
        }[props.subject];
    });

    const aspectRatioClass = computed(() => {
        return {
            square: 'aspect-square',
            full: 'aspect-full',
            wide: 'aspect-wide',
            ultrawide: 'aspect-ultrawide',
            picture: 'aspect-picture',
            portrait: 'aspect-portrait',
            unset: '',
        }[props.aspectRatio];
    });

    // const imageExists = ref(false);

    // const handleImageError = () => {
    //     imageExists.value = false;
    // };

    // onMounted(() => {
    //     // Simulate checking if the image exists in the database
    //     // Replace this with actual logic to check the database or API
    //     const checkImageExists = () => {
    //         const img = new Image();
    //         img.onload = () => {
    //             imageExists.value = true;
    //         };
    //         img.onerror = () => {
    //             imageExists.value = false;
    //         };
    //         img.src = props.src;
    //     };

    //     checkImageExists();
    // });
</script>
